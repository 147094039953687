<template>
  <div class="frame-domea-client-2">
    <div class="isMobile d-flex justify-content-between align-items-center header_web">
      <div class="left">
        <div class="d-flex align-items-center" @click="showMenu()" style="cursor: pointer">
          <img class="pr-2" src="@/static/icon/header-top/vector.svg" alt="">
          <span>MENU</span>
        </div>
        <div class="searchTop" @click="toggleSearch">
          <i class="el-icon-search search-icon" ></i>
          <div  class="index-search search-input" v-if="showSearch" style="width: 300px ; margin-left: 10px">
            <el-select style="width: 100%"
                       v-model="value"
                       filterable
                       remote
                       reserve-keyword
                       placeholder="Tìm kiếm"
                       :remote-method="remoteMethod"
                       @change="handleOptionChange"
                       :loading="loading">
              <el-option
                v-for="item in options"
                :key="item.key"
                :label="item.title"
                :value="item.key">
              </el-option>
            </el-select>
          </div>
        </div>
      </div>
      <div class="center" @click="toHome()" style="cursor: pointer">
        <img src="@/static/icon/header-top/logod.svg" alt="">
      </div>

      <div class="end" >
        <div class="d-flex">
          <div>
            <div class="d-flex" style="cursor: pointer; margin-right: 20px" @click="dowPdf()">
              <div class="brochure">e-Brochure</div>
              <div class="brochure-text"><i class="el-icon-bottom"></i>
              </div>
            </div>
          </div>
          <div style="margin-top: 5px" class="gtranslate_wrapper" @click="loadPage()">
          </div>
        </div>
      </div>
    </div>

    <!--    // phàn header, menu left mobile -->
    <div class="header_mobile">
      <div class="d-flex justify-content-between align-items-center">
        <img src="@/static/icon/header-top/logod.svg" alt="">
        <i style="font-size: 20px" @click="drawerIcon = true" class="el-icon-s-grid"></i>
      </div>
    </div>

    <div class="menu_left_mb">
      <el-drawer
        :visible.sync="drawerIcon"
        :direction="'ltr'"
        size="70%"
        :with-header="false"
        custom-class="account-drawer-change-pw"
      >
        <div style="padding: 50px 0 50px 0" class="d-flex align-items-center justify-content-center">
          <img src="@/static/icon/header-top/logod.svg" alt="">
        </div>
        <div class="sub_menu_left_mb" @click="toHome()">
          <span >Trang chủ</span>
        </div>
        <div class="sub_menu_left_mb" @click="goAboutUs()">
          <span >Về chúng tôi</span>
        </div>
        <!--<div class="sub_menu_left_mb" @click="goLVDT()">
          <span>Lĩnh vực đầu tư</span>
        </div>-->
        <div class="sub_menu_left_mb" @click="goToTinTuc()">
          <span>Tin tức</span>
        </div>
        <div class="sub_menu_left_mb" @click="goToTuyenDung()">
          <span>Tuyển dụng</span>
        </div>
        <div class="sub_menu_left_mb" @click="goToLienHe()">
          <span>Liên hệ</span>
        </div>
        <div class="d-flex justify-content-center" style="cursor: pointer;margin-top: 40px" @click="dowPdf()">
          <div class="brochure">e-Brochure</div>
          <div class="brochure-text"><i class="el-icon-bottom"></i>
          </div>
        </div>
        <div class="d-flex justify-content-center">
          <div style="margin-top: 8px" class="gtranslate_wrapper" @click="loadPage()">
        </div>
        </div>

      </el-drawer>
    </div>


  </div>
</template>

<script>
  import EventBus from '@/EventBus'
  import axios from "axios";
  export default {
    name: "HeaderTop",
    metaInfo() {
      return {
        title: "",
      };
    },
    components: {},
    data() {
      return {
        canSubmit: true,
        loading: true,
        drawerIcon: true,
        showSearch: false,
        options: [],
        value: [
          {
            key: '',
            title:'',
            source : '',
            id : ''
          }
        ],
        url: '',
      };
    },
    computed: {},
    mounted() {
      this.drawerIcon = false
    },
    methods: {
      dowPdf () {
        /*let data = document.querySelector('html');
        let classlang = data.getAttribute('class');
        if(classlang == null) {
           this.url = 'https://api.domea.vn/pdf/profile_vi.pdf';
        } else {
            this.url = 'https://api.domea.vn/pdf/profile_en.pdf';
        }
        const a = document.createElement('a');
        a.href = this.url;
        a.target = '_blank'
        a.download = 'profile_cn.pdf';
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);*/

        // Lấy thẻ <a> bằng class
        const langElement = document.querySelector('a.gt-current-lang');

        // Kiểm tra nếu thẻ tồn tại và lấy nội dung text
        if (langElement) {
          const langText = langElement.textContent;
          console.log(langText);

          if(langText == 'VI') {
            this.url = 'https://api.domea.vn/pdf/profile_vi.pdf';
          } else if (langText == 'EN') {
            this.url = 'https://api.domea.vn/pdf/profile_en.pdf';
          } else  {
            this.url = 'https://api.domea.vn/pdf/profile_cn.pdf';
          }
          const a = document.createElement('a');
          a.href = this.url;
          a.target = '_blank'
          a.download = 'profile_cn.pdf';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        }
      },
      toggleSearch() {
        this.showSearch = !this.showSearch;
      },
      loadPage(){
        setTimeout(() => {
          location.reload();
        }, 1000);

      },
      remoteMethod(query) {
        if (query !== '') {
          let rawData = {
            search: query,
          }
          this.loading = true;
          axios.get("home/search-post-recruitment",{params: rawData} ).then((response) => {
            this.loading = false;
            switch (response.code) {
              case 200:
                this.options = response.data;
                break;
              default:
                this.$notify.error({
                  title: "Error",
                  message: "Đã có lỗi xảy ra. Vui lòng liên hệ Admin !",
                });
                break;
            }
          });
        } else {
          this.options = [];
        }
      },
        handleOptionChange(){
          const myArray = this.value.split("_");
          let word = myArray[0];
          let id = myArray[1];
          let slug = myArray[2];
          let url = '';
          if (word == 'recruitment'){
            url = '/recruitment/show/'+id
          }else {
            url = '/new/'+slug
          }
          document.location.href = url
        },
      showMenu() {
        this.showSearch = false
        EventBus.$emit('showMenu', true);
      },
      toHome() {
        this.$router.push('/')
        this.drawerIcon = false;
      },
      goLVDT() {
        this.drawer = false;
      },
      goToTuyenDung() {
        this.drawerIcon = false;
        this.$router.push({ name: "recruitment" });
      },
      goToTinTuc(){
        this.drawerIcon = false;
        this.$router.push({ name: "new" });
      },
      goToLienHe() {
        this.drawerIcon = false;
        this.$router.push({ name: "contact" });
      },
      goAboutUs() {
        this.drawerIcon = false;
        this.$router.push({ name: "about-us" });
      },
    },
    watch: {

    },
  };
</script>

<style lang="css" scoped src="@/static/css/layout/headerTop.css">
#google_translate_element span {
  display: none;
}
</style>
<style>
.search-container {
  display: flex;
  align-items: center;
}

.search-icon {
  cursor: pointer;
}

.search-input {

}
#google_translate_element .goog-te-banner-frame.skiptranslate {
  display: none !important;
}
.brochure{
  padding: 5px 10px;
  background: #4823F2;
  color: white;
  border-bottom-left-radius: 10px;
  border-top-left-radius: 10px;
}
.brochure-text{
  padding: 5px 10px;
  background: #00D0FF;
  color: white;
  border-bottom-right-radius: 10px;
  border-top-right-radius: 10px;
}
.search_post .el-input-group__prepend {
  border-radius: 50px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background: #fff;
  border-right: 0;
  padding: 0 0 0 10px;
}

.search_post .el-input-group__append {
  border-radius: 50px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.index-search .el-input__inner{
  padding: 0 30px !important;
  border-radius: 20px!important;
}
.search_post input {
  border-left: none;
  border-right: none;
  outline: none !important;
}
  .menu_left_mb .el-drawer__body {
    background: #4823F2;
  }
</style>
